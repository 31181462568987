<template>
  <div class="app-container">
    <!-- 查询表单 -->
    <el-form :inline="true" :model="queryForm" ref="queryForm" size="small" style="margin-bottom: 20px">
      <el-form-item prop="gender">
        <el-select v-model="queryForm.type" style="width: 100px" @change="showChange">
          <el-option label="按图书搜" value="book" />
          <el-option label="按读者搜" value="reader" />
        </el-select>
      </el-form-item>
      <el-form-item prop="username">
        <el-input v-model="queryForm.keyWord" placeholder="输入读者卡号或乐兔码" clearable style="width:300px" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="querySubmit">查询</el-button>
      </el-form-item>
      <el-form-item style="position: absolute; right: 15px;">
       
        <el-button icon="el-icon-time" @click="openCondition">筛选</el-button>
        <el-button icon="el-icon-notebook-2" @click="excel.excelDialogVisible = true">导出</el-button>
        <ImportExcel :action="action"/>
      
        <!-- <el-button icon="el-icon-notebook-2">导出</el-button> -->
      </el-form-item>
    </el-form>
    <!-- 导出提示框 -->
    <div v-if="excel.excelDialogVisible">
          <el-dialog
            title="读者导出"
            :visible.sync="excel.excelDialogVisible"
            width="45%"
            :before-close="ExcelHandleClose"
            append-to-body>
            <template>
              <el-radio-group v-model="excel.excelRadio" size="mini" >
                <div >
                  <el-radio label="10"  v-if="multipleSelection.length>0">当前勾选的{{ multipleSelection.length }}条数据</el-radio>
                </div>
                <div >
                  <el-radio label="11" v-if="queryForm.keyWord != '' || lendRecordSiftDTOList.length >0">当前筛选的 {{ this.page.total }}条数据</el-radio>
                </div>
                <div>
                  <el-radio label="22">导出全部的{{ this.excel.excelTotalNumber }}条数据</el-radio>
                </div>
              </el-radio-group>
             
            </template>

            <span slot="footer" class="dialog-footer">
              <el-button @click="excel.excelDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="excel.excelDialogVisible = false ,lendExcel()">确 定</el-button>
            </span>
          </el-dialog>
        </div>

    <!-- 点击添加筛选后增加的 -->
    <div class="middle" v-if="openStatus != false && lendRecordSiftDTOList.length != 0"
      v-for="(item, index) in lendRecordSiftDTOList">
      <div style="display: flex; align-items: center;margin-right: 5px;">且</div>
      <el-select class="middle-item" v-model="item.field" placeholder="请选择" @change="clearContent(index,item)" >
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>

      <el-select v-if="lendRecordSiftDTOList[index].field == 1 || lendRecordSiftDTOList[index].field == 2" class="middle-item"
        v-model="item.condition" placeholder="请选择" :disabled="lendRecordSiftDTOList[index].field == ''">
        <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>

      <el-select v-else class="middle-item" v-model="item.condition" placeholder="请选择"
        :disabled="lendRecordSiftDTOList[index].field == ''">
        <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>

      <!-- 如果是借出时间 多时间选择 -->
      <el-date-picker v-if="lendRecordSiftDTOList[index].field == 1" :disabled="lendRecordSiftDTOList[index].condition == ''"
        v-model="item.content" type="date" placeholder="选择日期">
      </el-date-picker>
      <div v-if="lendRecordSiftDTOList[index].condition == 4 && lendRecordSiftDTOList[index].field == 1"
        style="display: flex;align-items: center;">~</div>
      <el-date-picker v-if="lendRecordSiftDTOList[index].condition == 4 && lendRecordSiftDTOList[index].field == 1"
        :disabled="lendRecordSiftDTOList[index].condition == ''" v-model="item.betweenContent" type="date" placeholder="选择日期">
      </el-date-picker>

      <!-- 如果是归还时间  多时间选择 -->
      <el-date-picker v-if="lendRecordSiftDTOList[index].field == 2" :disabled="lendRecordSiftDTOList[index].condition == ''"
        v-model="item.content" type="date" placeholder="选择日期">
      </el-date-picker>
      <div v-if="lendRecordSiftDTOList[index].condition == 4 && lendRecordSiftDTOList[index].field == 2"
        style="display: flex;align-items: center;">~</div>
      <el-date-picker v-if="lendRecordSiftDTOList[index].condition == 4 && lendRecordSiftDTOList[index].field == 2"
        :disabled="lendRecordSiftDTOList[index].condition == ''" v-model="item.betweenContent" type="date" placeholder="选择日期">
      </el-date-picker>

      <!-- 如果是借出馆  多选择框 -->
      <el-select v-if="lendRecordSiftDTOList[index].condition == 1 && lendRecordSiftDTOList[index].field == 3" class="middle-item"
        v-model="item.condition" placeholder="请选择" :disabled="lendRecordSiftDTOList[index].field == ''">
        <el-option v-for="item in allSubMsg" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>

      <!-- 如果是借阅规则 多选择框 -->
      <el-select v-if="lendRecordSiftDTOList[index].condition == 1 && lendRecordSiftDTOList[index].field == 4" class="middle-item"
        v-model="item.content" placeholder="请选择" :disabled="lendRecordSiftDTOList[index].field == ''" @blur="clearLendContent">
        <el-option v-for="item in optionsLendRule" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>

      <!-- 如果是年级 多选择框 -->
      <el-select v-if="lendRecordSiftDTOList[index].condition == 1 && lendRecordSiftDTOList[index].field == 6" class="middle-item"
        v-model="item.condition" placeholder="请选择" :disabled="lendRecordSiftDTOList[index].field == ''">
        <el-option v-for="item in optionsGrade" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>

      <el-input
        v-if="lendRecordSiftDTOList[index].field != 1 && lendRecordSiftDTOList[index].field != 2 && lendRecordSiftDTOList[index].field != 3 && lendRecordSiftDTOList[index].field != 4 && lendRecordSiftDTOList[index].field != 6 && lendRecordSiftDTOList[index].condition == 1"
        class="middle-item" v-model="item.content" placeholder="请输入内容"
        :disabled="lendRecordSiftDTOList[index].condition == ''"></el-input>

      <div class="circle" @click="delCondition">
        _<div class="circle-item"></div>
      </div>
    </div>


    <div class="bottomBak" v-if="openStatus != false">
      <div class="txtBak" @click="addCondition">+添加筛选条件</div>
      <div class="right">
        <el-button size="small" style="height: 35px;" @click="clearCondition">清空条件</el-button>
        <el-button type="primary" size="small" style="height: 35px;" @click="startCondition() ;clearBetween()" >开始筛选</el-button>
      </div>
    </div>

    <!-- 选项卡 -->
    <el-tabs type="border-card" v-model="activeTab" @tab-click="handleClick">
      <el-tab-pane v-for="(tab, index) in tabs" :key="index" :label="tab.label" :name="tab.name">
        <!-- 功能按钮组 -->
        <el-row v-if="false" :gutter="20" style="margin-bottom: 7px">
          <el-col :span="1.5">
            <el-button type="primary" size="small" icon="el-icon-plus" plain @click="handleAdd">添加</el-button>
          </el-col>
          <el-col :span="1.5">
            <el-button type="success" size="small" icon="el-icon-edit" plain @click="handleEdit(multipleSelection[0])"
              :disabled="editDisabled">编辑</el-button>
          </el-col>
          <el-col :span="1.5">
            <el-button type="danger" size="small" icon="el-icon-delete" plain @click="handleDelete"
              :disabled="removeDisabled">删除</el-button>
          </el-col>
        </el-row>

        <!-- 表格数据 -->
        <el-table ref="table" :data="tableData" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"/>
          <el-table-column prop="bookName" label="图书名称" />
          <el-table-column prop="letuCode" label="乐兔码" />
          <el-table-column prop="username" label="读者姓名" />
          <el-table-column prop="cardNo" label="读者卡号" />
          <el-table-column prop="lendTime" label="借阅日期" />
          <el-table-column prop="returnTime" label="归还日期" />
          <el-table-column prop="lendBranch" label="借出馆" />
          <el-table-column prop="returnBranch" label="归还馆" />
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status == 0" type="warning">未归还</el-tag>
              <el-tag v-else type="success">已归还</el-tag>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
       
        <!-- 编辑或添加对话框 -->
        <el-dialog :title="dialogTitle" :modal="false" :visible.sync="dialogFormVisible" width="35%" append-to-body>
          <el-form ref="dialogForm" :model="dialogForm" label-width="120px">
            <el-form-item label="借阅日期" prop="lendDate">
              <el-date-picker v-model="dialogForm.lendDate" type="datetime" placeholder="借阅日期" default-time="12:00:00"
                :picker-options="pickerOptions" value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="归还日期" prop="returnDate">
              <el-date-picker v-model="dialogForm.returnDate" type="datetime" placeholder="归还日期" default-time="12:00:00"
                :picker-options="pickerOptions" value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="是否归还" prop="status">
              <el-radio v-model="dialogForm.status" label="0">未归还</el-radio>
              <el-radio v-model="dialogForm.status" label="1">已归还</el-radio>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="cancelDialog">取 消</el-button>
            <el-button type="primary" @click="submitDialog">确 定</el-button>
          </div>
        </el-dialog>
      </el-tab-pane>
    </el-tabs>
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="page.current" :page-size="page.size" :page-sizes="[2, 5, 10, 20, 100]" :page-count="page.pageNum"
          layout="total, sizes, prev, pager, next, jumper" :total="page.total">
        </el-pagination>

  </div>
</template>

<script>
import { listLendRecord, addLendRecord, updateLendRecord, deleteLendRecord, exportLendExcel, getLendRecord} from '@/api/lendRecord'
import { listLibrary } from '@/api/library'
import ImportExcel from '../../../components/ImportExcel.vue'
export default {
  components:{
    ImportExcel
  },
  data() {
    return {
      //控制导出弹框显示的
      excel:{
                excelDialogVisible: false,
                excelRadio: '',
                excelTotalNumber: undefined,
                startConditionSelect: []
            },
      //上传url地址
      action:"https://api.leshop.cloud/lendRecord/import",
      // =================筛选=============
      openStatus: false,
      // 筛选的条件
      conditionList: [{
        field: "",
        condition: "",
        content: "",
        // 当选择总借阅次数的时候才会启用！
        betweenContent: "",
      }],
      lendRecordSiftDTOList: [],
      options: [{
        label: '借出时间',
        value: 1,
        disabled: false
      }, {
        label: '归还时间',
        value: 2,
        disabled: false
      }, {
        label: '借出馆',
        value: 3,
        disabled: false
      }, {
        label: '借阅规则',
        value: 4,
        disabled: false
      }, {
        label: '学校',
        value: 5,
        disabled: false
      }, {
        label: '年级',
        value: 6,
        disabled: false
      }, {
        label: '班次',
        value: 7,
        disabled: false
      }],
      // 为空 包含
      options1: [{
        label: '包含',
        value: 1
      }, {
        label: '为空',
        value: 2
      }, {
        label: '不为空',
        value: 3
      }],
      // 读者 图书
      options2: [{
        value: 'reader',
        label: '按读者搜'
      }, {
        value: 'book',
        label: '按图书搜'
      }],
      // 大于等于
      options3: [{
        value: 1,
        label: "等于"
      }, {
        value: 2,
        label: "大于等于"
      }, {
        value: 3,
        label: "小于等于"
      }, {
        value: 4,
        label: "介于"
      }],
      // 所有分馆信息选项
      allSubMsg: [],
      // 借阅规则选项
      optionsLendRule: [],
      // 年级选项
      optionsGrade: [{
        value: 1,
        label: "小班"
      }, {
        value: 2,
        label: "中班"
      }, {
        value: 3,
        label: "大班"
      }, {
        value: 4,
        label: "一年级"
      }, {
        value: 5,
        label: "二年级"
      }, {
        value: 6,
        label: "三年级"
      }, {
        value: 7,
        label: "四年级"
      }, {
        value: 8,
        label: "五年级"
      }, {
        value: 9,
        label: "六年级"
      }, {
        value: 10,
        label: "七年级"
      }, {
        value: 11,
        label: "八年级"
      }, {
        value: 12,
        label: "九年级"
      }, {
        value: 13,
        label: "高一"
      }, {
        value: 14,
        label: "高二"
      }, {
        value: 15,
        label: "高三"
      }, {
        value: 16,
        label: "毕业"
      }, {
        value: 17,
        label: "其他"
      }],

      // 查询表单数据
      queryForm:{
        type: 'book',
        keyWord: '',
      },
      // 按钮禁用
      editDisabled: true,
      removeDisabled: true,
      // 表格数据
      tableData: [],
      // 多选数据
      multipleSelection: [],
      // 分页数据
      page: {
        current: 1,
        pageNum: 1, // 当前页
        size: 10, // 每页显示数
        total: undefined // 总计
      },
      // 对话框类型[add|edit]
      dialogType: '',
      // 对话框是否可见
      dialogFormVisible: false,
      // 对话框标题
      dialogTitle: undefined,
      // 对话框数据
      dialogForm: {},
      // 日期选择器
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      //选项卡
      activeTab: '2',
      tabs: [
        { label: '全部', name: '2' },
        { label: '已归还', name: '1' },
        { label: '借阅中', name: '0' },
      ]
    }
  },
  created() {
    this.getList();
    this.lendRecordRule() 
    //this.getAllSubMsg();
  },
  updated(){
    //console.log("我执行了")
    this.getTotalNum()
   // console.log(this.excel.startConditionSelect)

  },
  methods: {
    ExcelHandleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
        },
    //获取当前的全部数据
    getTotalNum(){
            const queryParams = { page: this.page.current, size: this.page.size, status:this.activeTab,keyWord:"",type:this.queryForm.type }
            listLendRecord(queryParams).then(({data}  ) => {
              
            this.excel.excelTotalNumber = data.total
            })
        },


    clearLendContent(){

    },
    //借阅 规则数据渲染
    lendRecordRule(){
      console.log("我被点击率")
      getLendRecord().then(
        (res)=>{
          console.log(res)
          res.data.forEach(item=>{
            this.optionsLendRule.push({value:item.id, label:item.name})
          })
        })

    },
    //导出excel
    lendExcel(){
      let ids=[] 
      if(this.excel.excelRadio == 10){
                this.multipleSelection.forEach(w => {
                    console.log(w.id)
                    ids.push(JSON.parse(w.id))
            });
            }else if(this.excel.excelRadio == 11){
            console.log(this.excel.startConditionSelect)
            ids = this.excel.startConditionSelect 
            }else{

            }
            exportLendExcel(ids).then(
                 (res)=>{
                    
                    const str = res.headers['content-disposition'].split(';')[1].split('=')[1];
                    const filename = decodeURIComponent(str);

                   // 用blob存储二进制文件
                    const blob = new Blob([res.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8', //   application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8 设置文件类型 pdf是application/pdf，word是application/vnd.openxmlformats-officedocument.wordprocessingml.document，其他类型可以百度一下
                    });
                    // 创建一个临时的url指向blob对象
                    const objectUrl = window.URL.createObjectURL(blob);
                    // 创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
                    const a1 = document.createElement('a');
                    a1.setAttribute('href', objectUrl);
                    a1.setAttribute('download', filename);
                    //a1.setAttribute('_blank', );
                    document.body.appendChild(a1);
                    a1.click();
                    // 5.释放这个临时的对象url
                    document.body.removeChild(a1);
                    window.URL.revokeObjectURL(objectUrl);
                }
            );
    },

    //清空 between里面的内容
    clearContent(index,item){
                console.log(index)
                console.log(this.lendRecordSiftDTOList[index])
                this.lendRecordSiftDTOList[index].content=""
                this.lendRecordSiftDTOList[index].betweenContent=""
                this.lendRecordSiftDTOList[index].condition=""

    },    
    clearBetween(){
           this.lendRecordSiftDTOList.forEach((item)=>{
               if (item.condition != 4) {
                   item.betweenContent=""
               }
           })
       },        

    // ==================筛选=================
    // 开启筛选条件
    openCondition() {
      this.openStatus = !this.openStatus;
    },
    // 添加条件
    addCondition() {
      this.lendRecordSiftDTOList.push({
        "betweenContent": "",
        "condition": 0,
        "content": "",
        "field": 0
      })
      // console.log("增加一个", this.lendRecordSiftDTOList);
    },
    // 删除条件
    delCondition() {
      this.lendRecordSiftDTOList.pop()
      // console.log("删除一个", this.conditionList);
    },
    // 清空条件
    clearCondition() {
      this.conditionList = [{
        "betweenContent": "",
        "condition": 0,
        "content": "",
        "field": 0
      }];
      this.lendRecordSiftDTOList = [];
    },
    // 开始筛选
    startCondition() {
      console.log(this.lendRecordSiftDTOList);
      let data = { lendRecordSiftDTOList: this.lendRecordSiftDTOList, ...this.queryForm, status: this.activeTab,page: this.page.current, size: this.page.size }
      console.log(data);
      listLendRecord(data).then(data => {
        this.tableData = data.lendRecordData
        this.page.total = data.total
        this.page.current= data.current
        this.page.pageNum = data.pageNum
        this.excel.startConditionSelect = []
                        data.readerData.forEach((item)=>{
                        //console.log(item.book.id)
                        this.excel.startConditionSelect.push(item.id)
                    })
      })
    },
    // 获得所有分馆信息
    // getAllSubMsg() {
    //   listLibrary().then(({ data }) => {
    //     for (let i = 0; i < data.libraryBranchData.length; i++) {
    //       this.allSubMsg.push({ value: i, label: data.libraryBranchData[i].branchName })
    //     }
    //   })
      // console.log(this.allSubMsg);
    // },



    showChange(data) {
      console.log(data);
    },
    //============== 借阅记录API =============
    // 查询借阅记录列表
    getList() {
      const queryParams = {lendRecordSiftDTOList:this.lendRecordSiftDTOList, ...this.queryForm, page: "1", size: this.page.size, status: this.activeTab }
      listLendRecord(queryParams).then(({ data }) => {
        //console.log(data);
       // this.excel.startConditionSelect = []
        data.lendRecordData.forEach((item)=>{
                //console.log(item.book.id)
                this.excel.startConditionSelect.push(item.id)
        });
        this.tableData = data.lendRecordData
        this.page.total = data.total
        this.page.current= data.current
        this.page.pageNum = data.pageNum

      })
    },
    // 添加借阅记录
    addData() {
      addLendRecord(this.dialogForm).then(() => {
        this.msgSuccess('添加成功!')
        this.getList()
      })
    },
    // 修改借阅记录
    updateData() {
      const data = {
        isbn: this.dialogForm.isbn,
        readerId: this.dialogForm.readerId,
        lendDate: this.dialogForm.lendDate,
        returnDate: this.dialogForm.returnDate,
        status: this.dialogForm.status
      }
      updateLendRecord(data).then(() => {
        this.msgSuccess('修改成功!')
        this.getList()
      })
    },
    // 删除借阅记录
    deleteData(params) {
      deleteLendRecord(params).then(() => {
        this.msgSuccess('删除成功!')
        this.getList()
      })
    },
    handleClick(tab, event) {
      this.activeTab = tab.name
      //console.log(tab.name);
      this.getList()
    },
    //============== 查询表单 =================
    // 查询表单提交
    querySubmit() {
      console.log(this.queryForm)
      //this.getList()
      const queryParams = {lendRecordSiftDTOList:this.lendRecordSiftDTOList, ...this.queryForm, page: this.page.current, size: this.page.size, status: this.activeTab }
      listLendRecord(queryParams).then(({ data }) => {
        //console.log(data);
        this.excel.startConditionSelect = []
        data.lendRecordData.forEach((item)=>{
                //console.log(item.book.id)
                this.excel.startConditionSelect.push(item.id)
        });
        this.tableData = data.lendRecordData
        this.page.total = data.total
        this.page.current= data.current
        this.page.pageNum = data.pageNum

      })

    },
    //============== 表格 ==================
    // 多选改变
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.editDisabled = !(val.length === 1)
      this.removeDisabled = !(val.length > 0)
    },
    // 删除行数据
    handleDelete(row) {
      let data = { readerId: row.readerId, isbn: row.isbn }
      this.$confirm('此操作将永久删除该行数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteData(data)
        })
        .catch(() => { })
    },
    // 添加数据
    handleAdd() {
      // 清除对话框表单数据
      this.dialogForm = {}
      this.dialogType = 'add'
      this.dialogTitle = '添加借阅记录'
      this.showDialog()
    },
    // 编辑数据
    handleEdit(val) {
      console.log(val)
      this.dialogForm = val
      this.dialogType = 'edit'
      this.dialogTitle = '修改借阅记录'
      this.showDialog()
    },

    //============== 添加或编辑对话框 ===================
    // 显示对话框
    showDialog() {
      this.dialogFormVisible = true
    },
    // 对话框取消
    cancelDialog() {
      this.dialogFormVisible = false
    },
    // 对话框提交
    submitDialog() {
      console.log(this.dialogForm)
      if (this.dialogType == 'add') {
        // console.log('执行添加表单')
        this.addData()
      } else {
        // console.log('执行修改表单')
        this.updateData()
      }
      this.dialogFormVisible = false
    },

    //=============== 分页 ==================
    // 每页显示数改变
    handleSizeChange(val) {
       console.log(`每页 ${val} 条`)
      this.page.size = val
      let data = { lendRecordSiftDTOList: this.lendRecordSiftDTOList, ...this.queryForm, status: this.activeTab,page: this.page.current, size: this.page.size }
      console.log(data);
      listLendRecord(data).then((res) => {
      
        this.tableData = res.data.lendRecordData
        this.page.total = res.data.total
        this.page.current= res.data.current
        this.page.pageNum = res.data.pageNum
        
      })
    },
    // 当前页改变
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.page.current = val
      
      let data = { lendRecordSiftDTOList: this.lendRecordSiftDTOList, ...this.queryForm, status: this.activeTab,page: this.page.current, size: this.page.size }
      console.log(data);
      listLendRecord(data).then(res => {
        

        this.tableData = res.data.lendRecordData
        this.page.total = res.data.total
        this.page.current= res.data.current
        this.page.pageNum = res.data.pageNum
      })
    }
  }
}
</script>

<style scope>
.middle {
  display: flex;
  margin-top: 15px;
}

.middle .middle-item {
  margin-left: 10px;
}

.middle .circle {
  font-size: 20px;
  color: rgb(237, 64, 20);
  position: relative;
  margin-left: 10px;
}

.middle .circle-item {
  position: absolute;
  border: 1px solid rgb(237, 64, 20);
  border-radius: 50%;
  height: 14px;
  width: 14px;
  top: 14px;
  right: -2.5px;
  cursor: pointer;
}

.bottomBak {
  display: flex;
  margin-top: 15px;
}

.bottomBak .txtBak {
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #DCDFE6;
  color: #2d8cf0;
  cursor: pointer;
  line-height: 1;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-right: 40px;
  margin-bottom: 6px;
}

.bottomBak .right {
  display: flex;
}
</style>